import { MacroProps } from './macro-props'

export const getInt = (parameterName: string, parameters: MacroProps, defaultValue: number) => {
  const parameterValue = parameters[parameterName]

  return (parameterValue && parseInt(parameterValue, 10)) || defaultValue
}

export const getString = (parameterName: string, parameters: MacroProps) => {
  const parameterValue = parameters[parameterName]

  return parameterValue || ''
}

export const getBoolean = (parameterName: string, parameters: MacroProps) => {
  const parameterValue = parameters[parameterName]

  return parameterValue === '1'
}

export const getCommaSeparatedStrings = (parameterName: string, parameters: MacroProps) => {
  return (getString(parameterName, parameters) || null)?.split(',') || []
}

export const getCommaSeparatedInts = (parameterName: string, parameters: MacroProps) => {
  return getCommaSeparatedStrings(parameterName, parameters).map(id => parseInt(id))
}
