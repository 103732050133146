'use client'

import React from 'react'
import {
  getBoolean,
  getCommaSeparatedInts,
  getCommaSeparatedStrings,
  getInt,
} from './macro-parameter-extractor'
import {
  GetArticleBoxListQuery,
  GetArticleBoxListQueryVariables,
} from '@dustin-web/microsite-data/src/graphql/content/generated/graphql-types'
import dynamic from 'next/dynamic'

import { MacroProps } from './macro-props'
import { useGraphql } from '@dustin-web/microsite-data/src/hooks/use-graphql'
import { GetArticleBoxList } from 'src/graphql/get-article-box-list.graphql'

const ArticleCardList = dynamic(
  () => import('@dustin-web/content-components/src/article-card-list')
)

export const Articlebox = (props: MacroProps) => {
  const numberOfBoxes = getInt('numberofboxes', props, 0)
  const startIndex = getInt('articleindexstart', props, 1) - 1
  const tagNames = getCommaSeparatedStrings('tagname', props)
  const mustIncludeAllTags = getBoolean('mustincludealltags', props)
  const categoryNames = getCommaSeparatedStrings('categorynames', props)
  const articleIds = getCommaSeparatedInts('articleids', props)
  const showFourBoxesPerRow = getBoolean('showFourBoxesPerRow', props)
  const hideCategoryName = getBoolean('hideCategoryName', props)

  const { data } = useGraphql<GetArticleBoxListQuery, GetArticleBoxListQueryVariables>(
    GetArticleBoxList,
    {
      variables: {
        mustIncludeAllTags,
        numberOfBoxes,
        startIndex,
        tagNames,
        categoryNames,
        articleIds,
      },
    }
  )

  const articleBoxList = data?.articleBoxList

  if (!articleBoxList) {
    return null
  }

  return (
    <ArticleCardList
      articlesList={articleBoxList.articles}
      showFourCardsPerRow={showFourBoxesPerRow}
      hideCategoryName={hideCategoryName}
      maxNumberOfArticles={numberOfBoxes}
      blogUrl={articleBoxList.blogUrl}
    />
  )
}

export default Articlebox
